import persona from './persona';
export default {
     components:{persona},
     data(){
          return {
               options : []
          }
     },
     mounted(){
          this.update();
     },
     methods:{
          update(){
               this.options = [1,2,3];
          }
     }
}
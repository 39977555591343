
var _subs = false;
export default {
     data() {
          return {
               step:1,
               source: '',
               movil: '',
               options: [],
               password: '',
               reg_password: '',
               reg_repeat_password: '',
               showErr: '',

               snackbar: false,

               invalidPass: 3,
               invalidUser: 3,
               valid_movil: false,
               valid_pass: false,
               valid_test: false,
          }
     },
     watch: {
          showErr(msg) {
               this.snackbar = msg.length > 0;
               var _this = this;
               if (msg.length > 0) {
                    setTimeout(() => { _this.showErr = '' }, 8000)
               }
          }
     },
     computed: {
          getTestValid() {
               switch (this.step) {
                    case 1:
                         console.log("stp x", this.valid_movil);
                         return this.valid_movil;
                         break;
                    case 2:
                         if (this.reg_password != this.reg_repeat_password) {
                              return false;
                         }
                         console.log("stp x", this.valid_pass);
                         return this.valid_pass;
                         break;
                    case 3:
                         return this.valid_test;
                         break;
               }
          }
     },

     mounted() {
          var _this = this;
          if (typeof _subs == 'function') {
               _subs();
          }
          _subs = this.$store.subscribe(({ type, payload }) => {
               if (type == 'users/logged') {
                    var payload = parseInt(payload);
                    console.log('payload', payload);
                    switch (payload) {
                         //no usuario
                         case -3:
                              _this.showErr = 'Usuario bloqueado';
                              this.invalidPass=0;
                              this.movil = '';
                              this.invalidUser--;
                              break;
                         case -1:
                              _this.showErr = 'Usuario no existente';
                              this.movil = '';
                              this.invalidUser--;
                              break;
                         case -2:
                              _this.showErr = 'Contraseña no válida';
                              this.password = "";
                              this.invalidPass--;
                              this.$store.dispatch('users/blockPass', { movil: this.movil, password: this.password });
                              break;
                         case 0:
                              //si usuario / sin registro de contraseña
                              _this.showErr = '';
                              _this.step = 2;
                              break;
                         case 1:
                              //si usuario / con registro de contraseña
                              _this.showErr = '';
                              _this.step = 3;
                              break;
                         case 2:
                              // contraseña aprobada
                              _this.showErr = '';
                              _this.step = 10;
                              this.$router.push('/dashboard');
                              break;

                    }
                    this.$store.commit("iface/setWait", { wait: false, text: "" });
               }

          })
          this.update();
     },
     methods: {
          next() {
               this.$store.commit("iface/setWait", { wait: true, text: "" });
               switch (this.step) {
                    case 1:
                         this.$store.dispatch('users/get', this.movil);
                         break;
                    case 2:
                         this.$store.dispatch('users/sendPass', { movil: this.movil, password: this.reg_password });
                         break;
                    case 3:
                         this.$store.dispatch('users/testPass', { movil: this.movil, password: this.password });
                         break;
               }
          },
          async update() {
               this.$store.commit("iface/setWait", { wait: true, text: "" });
               await this.$store.dispatch('users/init')
               this.$store.commit("iface/setWait", { wait: false, text: "" });
               this.options = [1, 2, 3];
          }

     }
}
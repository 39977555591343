<template>
	<v-container fluid class="maxwidth">
          <wait />
		<v-elections ></v-elections>
	</v-container>
</template>



<script>

import vElections from './elections/elections.vue';
import wait from "./wait.vue";

export default {
     components:{vElections,wait},
	name: "InitTemplate",

	data: () => ({
		
     }),
     mounted(){
     }
};
</script>

<style scoped>
.maxwidth{
     max-width:100% !important;
}
</style>

<template>
  <div class="home">
    <init-user />
  </div>
</template>

<script>
// @ is an alias to /src
import initUser from '@/components/InitUser.vue'

export default {
  name: 'User',
  components: {
    initUser
  }
}
</script>

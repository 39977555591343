<template>
  <div class="home">
    <init-vote />
  </div>
</template>

<script>
// @ is an alias to /src
import initVote from '@/components/InitVote.vue'

export default {
  name: 'Home',
  components: {
    initVote
  }
}
</script>
